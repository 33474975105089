class StepsCompare {

  constructor($root) {
    $root.addClass('initialized');
    this.switch($root, 0, true)
    this.addEvents($root);
  }

  switch($root, i, init = false) {
    $root.find('[data-stepscompare-togglebtn]').each((j, el) => {
      if (i === j) {
        $(el).addClass('active');
      } else {
        $(el).removeClass('active');
      }
    });

    $root.find('[data-stepscompare-step]').each((j, el) => {
      if (init) {
        if (i !== j) $(el).hide();
      } else {
        if (i === j) {
          setTimeout(() => {
            $(el).fadeIn();
          }, 300)
        } else {
          $(el).fadeOut();
        }
      }

    });
  }

  addEvents($root) {
    const _this = this;
    $root.find('[data-stepscompare-togglebtn]').click(function() {
      _this.switch($root, Number($(this).data('stepscompare-togglebtn')));
    });
  }
}

readyCallbacks.add(() => {
  $('[data-stepscompare]:not(.initialized)').each(function() {
    new StepsCompare($(this));
  });
});